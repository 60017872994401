// src/js/main.js
import "../css/styles.scss"; // Import the SCSS file

// src/js/nav.js
import { initNav } from "./nav";

// Run Scripts

document.addEventListener("DOMContentLoaded", () => {
  initNav();

  console.log(
    "%cHey there, mate! 👋",
    "font-size: 16px; color: #4caf50; font-weight: bold;"
  );
  console.log(
    "%cI'm Waqas Bajwa, a passionate Front-End Developer!",
    "font-size: 14px; color: #2196f3;"
  );
  console.log(
    "%cI craft clean, responsive, and efficient websites that deliver exceptional user experiences.",
    "font-size: 14px; color: #ff9800;"
  );
  console.log(
    "%cLooking for someone who loves building awesome web apps? Let's connect!",
    "font-size: 14px; color: #673ab7; font-weight: bold;"
  );
  console.log(
    "%cReach me at: %cwaqasbajwa.com",
    "font-size: 14px; color: #e91e63;",
    "font-size: 14px; color: #000; text-decoration: underline;"
  );
});
