export function initNav() {
  const menuIcon = document.getElementById("menu-icon");
  const menu = document.getElementById("menu");
  const menuItems = document.querySelectorAll(".menu-item");

  menuIcon.addEventListener("click", function () {
    if (menu.classList.contains("show")) {
      menu.classList.remove("show");
      menuIcon.innerHTML = `<i class="fa-solid fa-bars"></i>`; // Hamburger icon
    } else {
      menu.classList.add("show");
      menuIcon.innerHTML = `<i class="fa-solid fa-xmark"></i>`; // Cross icon
    }
  });

  menuItems.forEach((item) => {
    item.addEventListener("click", function () {
      // Remove active class from all items
      menuItems.forEach((i) => i.classList.remove("active"));
      // Add active class to the clicked item
      item.classList.add("active");
      // Close the menu
      menu.classList.remove("show");
      menuIcon.innerHTML = `<i class="fa-solid fa-bars"></i>`; // Hamburger icon
    });
  });

  // Add smooth scroll behavior for anchor links
  document.querySelectorAll(".menu-item").forEach((anchor) => {
    anchor.addEventListener("click", function (e) {
      e.preventDefault();
      const targetId = this.getAttribute("href").substring(1);
      document.getElementById(targetId).scrollIntoView({
        behavior: "smooth",
      });
    });
  });

  // Highlight the current section in the navigation
  window.addEventListener("scroll", () => {
    let currentSection = "";
    document.querySelectorAll("section").forEach((section) => {
      const sectionTop = section.offsetTop;
      const sectionHeight = section.clientHeight;
      if (pageYOffset >= sectionTop - sectionHeight / 3) {
        currentSection = section.getAttribute("id");
      }
    });

    // Check if we are at the top of the page and set the first section active
    if (window.pageYOffset === 0) {
      currentSection = document.querySelector("section").getAttribute("id");
    }

    document.querySelectorAll(".menu-item").forEach((anchor) => {
      anchor.classList.remove("active");
      if (anchor.getAttribute("href").substring(1) === currentSection) {
        anchor.classList.add("active");
      }
    });
  });
}
